import { FC, useState } from 'react';
import { getCities } from '@api/data/cities';
import { getStories } from '@api/data/stories';
import { Analytics, AnalyticsSearchType } from '@components/analytics/analytics';
import { spanishLabelToCountryCodeMapper } from '@components/common/country/countries';
import { useRentennialsStories } from '@store/stories';
import { useVehicleSearchFilter } from '@store/vehicleSearhFilterCriteria';
import { useTranslation } from 'next-i18next';

import { SearchParamsType } from '../../api/types/searchParamsSchema';

import SearchBar, { SearchBarProps } from './SearchBar';
import SearchButton from './SearchButton';
import SearchModal from './SearchModal';

interface SearchProps extends Omit<SearchBarProps, 'currentDefaultSearchValues' | 'noDates' | 'setNoDates'> {
  searchType?: 'component' | 'button';
  buttonSearchClassName?: string;
  currentDefaultSearchValues?: SearchParamsType;
  hideInitialDates?: boolean;
}

const Search: FC<SearchProps> = ({
  searchType = 'component',
  cities,
  onSearchBarSubmit,
  className,
  buttonSearchClassName,
  currentDefaultSearchValues,
  size,
  hideInitialDates = false,
  cityPickSX
}) => {
  const { t } = useTranslation('search');
  const [noDates, setNoDates] = useState<boolean>(false || hideInitialDates);
  const [filterChanged, setFilterChanged] = useState<boolean>(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState<boolean>(false);
  const { city, country, ...searchValues } = currentDefaultSearchValues || {};
  const { setStories, reset } = useRentennialsStories();
  const { setSearchVehicleParams } = useVehicleSearchFilter();

  const handleSearchBarSubmit = async (searchParams: SearchParamsType) => {
    setNoDates(false);
    Analytics.event(AnalyticsSearchType.WEB_SEARCH_BUTTON, {
      ...searchParams
    });
    await onSearchBarSubmit(searchParams);
  };

  const handleSearch = async (searchParams: SearchParamsType) => {
    reset();
    const cities = await getCities();
    const currentCity = cities?.find(({ id }) => id === searchParams.city);
    const stories = await getStories({
      city: currentCity && currentCity.name,
      country: searchParams.country
    });
    setSearchVehicleParams({
      fromDate: searchParams.fromDate,
      toDate: searchParams.toDate,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      region: currentCity?.name,
      country: (currentCity && spanishLabelToCountryCodeMapper[currentCity.country]) || searchParams.country
    });
    setStories(stories.data);

    await handleSearchBarSubmit(searchParams);
    setIsSearchDialogOpen(false);
  };

  const possibleCity = cities.find(({ id }) => id === city);

  return searchType === 'button' ? (
    <>
      <SearchButton
        onOpenSearchBarModal={() => setIsSearchDialogOpen(true)}
        cities={cities}
        noDates={noDates}
        searchValues={{
          ...searchValues,
          ...(city && {
            city: possibleCity?.name
          }),
          ...((city || country) && {
            country: (possibleCity?.country && spanishLabelToCountryCodeMapper[possibleCity.country]) || country
          })
        }}
        className={buttonSearchClassName}
      />
      {isSearchDialogOpen && (
        <SearchModal
          cities={cities}
          onSearchBarSubmit={handleSearch}
          className="flex flex-col justify-between gap-x-3 gap-y-6  bg-white px-4 py-7"
          currentCity={city}
          currentCountry={country}
          currentDefaultSearchValues={searchValues}
          searchLabel={t('search-alt')}
          buttonClassName="h-8"
          size={size}
          onCancel={() => setIsSearchDialogOpen(false)}
          noDates={noDates}
          setNoDates={setNoDates}
        />
      )}
    </>
  ) : (
    <SearchBar
      cities={cities}
      onSearchBarSubmit={handleSearchBarSubmit}
      className={className}
      currentCity={city}
      currentCountry={country}
      currentDefaultSearchValues={searchValues}
      size={size}
      noDates={noDates}
      cityPickSX={cityPickSX}
      setNoDates={setNoDates}
    />
  );
};

export default Search;
